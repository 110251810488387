/* 
  Initial settings
*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.4s ease-in;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
  SCROLLBAR

  Always visible, custom appearance


#root {
  overflow-y: scroll;
}
*/

/* FONTS */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

/*
@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Sohne-Dreiviertelfett.eot');
  src: url('./assets/fonts/Sohne-Dreiviertelfett.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Sohne-Dreiviertelfett.woff2') format('woff2'),
    url('./assets/fonts/Sohne-Dreiviertelfett.woff') format('woff'),
    url('./assets/fonts/Sohne-Dreiviertelfett.ttf') format('truetype'),
    url('./assets/fonts/Sohne-Dreiviertelfett.svg#Sohne-Dreiviertelfett') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Sohne-Leicht.eot');
  src: url('./assets/fonts/Sohne-Leicht.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Sohne-Leicht.woff2') format('woff2'),
    url('./assets/fonts/Sohne-Leicht.woff') format('woff'),
    url('./assets/fonts/Sohne-Leicht.ttf') format('truetype'),
    url('./assets/fonts/Sohne-Leicht.svg#Sohne-Leicht') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Sohne-Buch.eot');
  src: url('./assets/fonts/Sohne-Buch.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Sohne-Buch.woff2') format('woff2'),
    url('./assets/fonts/Sohne-Buch.woff') format('woff'),
    url('./assets/fonts/Sohne-Buch.ttf') format('truetype'),
    url('./assets/fonts/Sohne-Buch.svg#Sohne-Buch') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/



/* GLOBAL OVERRIDES */

/* To align font awesome icons: */
i {
  vertical-align: middle;
}

.font-fancy {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: var(--bs-primary);
}

.text-outline-primary {
  paint-order: stroke;
  color: var(--bs-primary);
  fill: var(--bs-primary);
}


.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-gray-600) !important
}

/* OTHER UTILITIES  */

/* 
  Utility to remove the last element of list
  Parent element must have the .d-none-last-parent class
  Child element must have the .d-none-last-child class
*/
.d-none-last-parent:last-child .d-none-last-child {
  display: none;
}

/* Adds the hand cursor to elements */
.cursor-pointer {
  cursor: pointer !important;
}

/* Makes a simple hover effect */
.hoverable:hover {
  opacity: .5;
}

/* Hide scroll */
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollhide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollhide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mw-0 {
  min-width: 0 !important;
}


.text-hoverable:hover {
  color: var(--bs-primary) !important;
}

.remove-margin-bottom-last div:last-of-type {
  margin-bottom: 0 !important;
}

/* CIRC PROG BAR */
.progress-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.progress-circle {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid #ccc;
  border-radius: 50%;
  animation: progress 1s ease-out;
  clip: rect(0, 40px, 80px, 0);
}

.progress-circle:nth-child(1) {
  z-index: 2;
  border-color: #00ff00;
  animation-delay: -1s;
}

@keyframes progress {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* TODO: see if this is the best way to handle this */
.CircularProgressbar-text {
  dominant-baseline: central !important;
}

.CircularProgressbar-background {
  fill: var(--bs-body-bg) !important;
}

/* PAGE INDICATOR */

.page-indicator {
  width: 0.8rem;
  height: 0.8rem;
  background-color: var(--bs-secondary-bg);
  margin-top: 2px;
}

.page-indicator.active {
  background-color: var(--bs-primary);
}

/* SLIDES */
.right-to-left-enter {
  transform: translateX(100%);
}

.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.right-to-left-exit {
  transform: translateX(0);
}

.right-to-left-exit-active {
  transform: translateX(-100%);
  transition: all 1s ease;
}

.left-to-right-enter {
  transform: translateX(-100%);
}

.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.left-to-right-exit {
  transform: translateX(0);
}

.left-to-right-exit-active {
  transform: translateX(100%);
  transition: all 1s ease;
}

.quiz-explanation-enter {
  opacity: 0;
}

.quiz-explanation-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.quiz-explanation-exit {
  opacity: 1;
}

.quiz-explanation-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


.bg-success-solid {
  background: #c9d7d1 !important;
}

.bg-danger-solid {
  background: #e1c9cf !important;
}

.loader {
  animation: pulse 2s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.text-shadowed {
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}


.CircleButton {
  transition: all 0.15s ease-in-out;
}

/* .CircleButton:not(.disabled):hover {
  transform: scale(1.1);
} */


/* .bg-gradient-to-dark {
  background: linear-gradient(to bottom, #1B1E3B 80%, #484F93 100%);
}

.bg-gradient-to-light {
  background: linear-gradient(to bottom, #484F93 80%, #1B1E3B 100%);
} */
.react-tooltip {
  max-width: 20rem !important;
}

/*Effect*/
.card-hover {
  position: relative;
  cursor: pointer;
}

.card-hover .opacity-0,
.card-hover .opacity-100 {
  transition: opacity 0.3s ease-in-out;
}

.card-hover .card-lifted,
.card-hover.card-lifted {
  transition: transform 0.25s ease-in-out;
}

.card-hover:hover .opacity-0 {
  opacity: 1 !important;
}

.card-hover:hover .opacity-100 {
  opacity: 0 !important;
}

.card-hover:hover .card-lifted,
.card-hover:hover.card-lifted {
  transform: translateY(-0.3rem);
}

@media (max-width: 640px) {
  .EduQuizLearnView.custom-height {
    min-height: calc(100vh + 128px);
  }
}

button:focus:not(:focus-visible) {

  opacity: 1 !important;
}

.form-control {
  background-color: var(--bs-white);
}

.react-tooltip {
  max-width: 20rem !important;
}

/* QUILL INTEGRATION */
.ql-toolbar {
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-color) 2px solid !important;
}

.ql-container {
  border-radius: var(--bs-border-radius) !important;
  border: var(--bs-border-color) 2px solid !important;
  margin-top: 0.5rem !important;
}

/* Coin */
.coin {
  height: 4rem;
  width: 4rem;
  position: absolute;
  z-index: 19999;
  transition: all 0.8s ease-in;
}

.sparks {
  position: absolute;
  z-index: 19999;
  height: 12rem;
  width: 12rem;
}

.coingrow {
  animation: coingrow 0.6s ease-in;
}

@keyframes coingrow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.coinshrink {
  animation: coinshrink 1.7s ease-in;
}

@keyframes coinshrink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.passwordCheckIcon {
  width: 1.5rem;
  margin-left: 0.5rem;
}

.blurrato:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(27, 31, 59, 0.8), rgba(27, 31, 59, 0));
  border-radius: var(--bs-border-radius);
}

#sidebar[data-closed="true"] {
  display: none;
}

/* FLAG COMPONENTS */
.FlagWrapper>img {
  height: 18px;
  width: 22px;
  object-fit: cover;
  padding-bottom: 2px;
}

.BigFlagWrapper>img {
  height: 24px;
  width: 32px;
  margin-right: 1rem;
  padding-bottom: 2px;
}

.css-13cymwt-control {
  border-radius: var(--bs-border-radius) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;

}

.flickity-prev-next-button.previous,
.flickity-prev-next-button.next {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  width: 30px;
  height: 30px;
  z-index: 2000;
}

.flickity-prev-next-button.previous {
  left: -14px;
}

.flickity-prev-next-button.next {
  right: -14px;
}

.flickity-viewport {

  width: 100%;
}

.shadowRilio {
  background: -moz-linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 0.7987788865546218) 22%, rgba(244, 244, 244, 0.8043811274509804) 100%);
  background: -webkit-linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 0.7987788865546218) 22%, rgba(244, 244, 244, 0.8043811274509804) 100%);
  background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 0.7987788865546218) 22%, rgba(244, 244, 244, 0.8043811274509804) 100%);
}

.dark .shadowRilio {
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
}

.custom-range::-webkit-slider-thumb {
  background: var(--bs-primary) !important;
}

.custom-range::-moz-range-thumb {
  background: var(--bs-primary) !important;
}

.custom-range::-ms-thumb {
  background: var(--bs-primary) !important;
}

-webkit-slider-thumb:active {
  background-color: var(--bs-primary) !important;
}

-webkit-slider-thumb,
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
  box-shadow: var(--bs-primary) !important;
}

.text-transparent {
  color: transparent !important;
}

.wobble-children :nth-child(2n) {
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
}

.wobble-children :nth-child(2n-1) {
  animation-name: keyframes2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 30% 5%;
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-0.4deg);
    animation-timing-function: ease-in-out
  }

  50% {
    transform: rotate(0.4deg);
    animation-timing-function: ease-in-out
  }

  100% {
    transform: rotate(-0.4deg);
    animation-timing-function: ease-in-out
  }
}

@keyframes keyframes2 {
  0% {
    transform: rotate(0.45deg);
    animation-timing-function: ease-in-out
  }

  50% {
    transform: rotate(-0.45deg);
    animation-timing-function: ease-in-out
  }

  100% {
    transform: rotate(0.45deg);
    animation-timing-function: ease-in-out
  }
}

.dark .d-dark-none {
  display: none !important;
}

html:not(.dark) .d-light-none {
  display: none !important;
}

.btn:focus-visible {
  box-shadow: none !important;
}

@media screen and (min-width: 768px) {
  .player {
    min-height: 420px;
  }

}

.text-truncate-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.text-truncate-3 {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-2 {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-4 {
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-5 {
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-6 {
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-7 {
  display: -webkit-box;
  line-clamp: 7;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-8 {
  display: -webkit-box;
  line-clamp: 8;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-9 {
  display: -webkit-box;
  line-clamp: 9;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-10 {
  display: -webkit-box;
  line-clamp: 10;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 
  TUTORIAL TOUR
*/

.helpbox {
  max-width: 360px !important;
}

/* TEXT WRAPPING */

.text-pre {
  white-space: pre-wrap;
}

p {
  white-space: pre-wrap !important;
}

/* Definire il nuovo breakpoint */
@media (min-width: 1600px) {

  /* Griglia generale */
  .container-xxxl {
    max-width: 1800px;
  }

  /* Supporto per il sistema di colonne .row-cols-xxxl-* */
  .row-cols-xxxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxxl-3>* {
    flex: 0 0 auto;
    width: 33.333333%;
  }

  .row-cols-xxxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxxl-6>* {
    flex: 0 0 auto;
    width: 16.666667%;
  }

  .row-cols-xxxl-7>* {
    flex: 0 0 auto;
    width: 14.285714%;
  }

  .row-cols-xxxl-8>* {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .row-cols-xxxl-9>* {
    flex: 0 0 auto;
    width: 11.111111%;
  }

  .row-cols-xxxl-10>* {
    flex: 0 0 auto;
    width: 10%;
  }

  .row-cols-xxxl-11>* {
    flex: 0 0 auto;
    width: 9.090909%;
  }

  .row-cols-xxxl-12>* {
    flex: 0 0 auto;
    width: 8.333333%;
  }
}

/* Opzionale: aggiungere il container per il breakpoint xxl */
.container-xxxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}